import * as React from 'react';
import { graphql } from 'gatsby';
import {
  Layout,
  SEO,
  CRestaurantJumbotron,
  CBreadCrumb,
  LWrap,
  LRestaurant,
  CPanelCard,
  LReservation,
  CHorizSlider,
  LRestaurantPickup,
  LRestaurantContact,
  CBtnList,
} from '../../../../components/_index';

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;

  return (
    <Layout>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CRestaurantJumbotron
        size="large"
        data={{
          location: {
            floor: '70F',
            category: 'スカイラウンジ',
          },
          logo: {
            src: '/assets/images/restaurants/sirius/logo.svg',
            alt: '',
          },
          title: {
            en: 'VIEW',
            ja: '眺望',
          },
          imgList: [
            {
              img: {
                src: '/assets/images/restaurants/sirius/view/kv.png',
              },
              imgSp: {
                src: '/assets/images/restaurants/sirius/view/kv__sp.png',
              },
            },
          ],
        }}
      >
        <CBreadCrumb
          data={{
            parent: [
              {
                label: 'レストラン&バー',
                path: '/restaurants/',
              },
              {
                label: '70F スカイラウンジ シリウス',
                path: '/restaurants/sirius/',
              },
            ],
            current: {
              label: '眺望',
            },
          }}
        />
      </CRestaurantJumbotron>
      <section className="l_sect">
        <LWrap>
          <h3 className="c_headingLv2 u_mb20">南東・山下公園</h3>
          <CHorizSlider
            exClass="u_mb30"
            data={[
              {
                img: {
                  src: '/assets/images/restaurants/sirius/view/img_view_se.png',
                  alt: '',
                },
              },
              {
                img: {
                  src: '/assets/images/restaurants/sirius/view/img_view_se02.png',
                  alt: '',
                },
              },
            ]}
          />
          <p className="c_sectLead">
            横浜ベイブリッジ・山下公園・横浜スタジアムなど、港町横浜が一望できる方向です。
            <br />
            （大桟橋　赤レンガ倉庫　山下公園　氷川丸　マリンタワー　中華街　横浜スタジアム）
          </p>

          <h3 className="c_headingLv2 u_mb20">北東・ベイサイド</h3>
          <CHorizSlider
            exClass="u_mb30"
            data={[
              {
                img: {
                  src: '/assets/images/restaurants/sirius/view/img_view_ne.png',
                  alt: '',
                },
              },
              {
                img: {
                  src: '/assets/images/restaurants/sirius/view/img_view_ne02.png',
                  alt: '',
                },
              },
            ]}
          />
          <p className="c_sectLead">
            横浜ベイブリッジなど、シーサイドの主な景観がご覧いただける方向です。
            <br />
            （臨港パーク　パシフィコ横浜　横浜コスモワールド・大観覧車　横浜ベイブリッジ）
          </p>

          <CBtnList
            exClass="u_mbLarge"
            data={[
              {
                label: 'シリウストップへ',
                link: {
                  href: '/restaurants/sirius/',
                },
              },
            ]}
          />
        </LWrap>
      </section>
      <section>
        <LRestaurant />
      </section>
      <section className="l_sect">
        <LWrap>
          <ul className="c_noteList">
            <li>画像は全てイメージです。</li>
            <li>料金には消費税、サービス料が含まれます。</li>
            <li>料理の表示価格は、特別な記載がない限り1名様分の料金です。</li>
            <li>
              テイクアウト商品の料金には消費税（8％）のみ含まれます。コフレ店内をご利用の場合は、サービス料を加算、消費税は10％となります。
            </li>
            <li>入荷状況によりメニュー内容に変更がある場合がございます。</li>
            <li>
              「神奈川県公共的施設における受動喫煙防止条例」に基づきレストラン・バーを全席禁煙とさせていただいております。
            </li>
            <li>
              ご入店の際は、タンクトップ・短パン・ビーチサンダルなどはご遠慮いただいております。
            </li>
          </ul>
        </LWrap>
      </section>
      <LRestaurantContact />
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
